import { Response } from 'node-fetch';
import { CartApi, NewCartApi, OldCartApi } from './CartApiService';
import { formatShippingMethod } from './CartUtils';
import { getApplePayOrder, getApplePayOrderForRestCart } from './CartUtils';
import {
  ApplePayOrderForRestCartArgs,
  AvailableShippingMethods,
  CartApiResponse,
  CartContactInfo,
  CartTax,
  Configuration,
  LambdaV2Responses,
  PhysicalAddress,
  Request,
  RequestInit,
  RestExpressCheckoutResponse,
  ShippingMethod,
  StripeToken,
  VolusionApi,
} from './types';

type RequestFn = (
  url: Request | string,
  init?: RequestInit
) => Promise<Response>;

export class LambdaApiV2 implements VolusionApi {
  private _baseUrl = 'https://api.material.com';
  private _useNewCart: boolean | null = null;

  constructor(private _transport: RequestFn, private _config: Configuration) {
    if (_config.apiUri) {
      this._baseUrl = _config.apiUri;
    }
  }

  public getStoreInformation(): Promise<LambdaV2Responses['storeInformation']> {
    return this._transport(`${this._baseUrl}/storeinformation`, {
      headers: {
        'x-vol-tenant': this._config.tenant,
      },
    })
      .then((res) => res.json())
      .catch(this.logAndThrow);
  }

  public getProductById(id: string): Promise<LambdaV2Responses['product']> {
    return this._transport(`${this._baseUrl}/store/products/${id}`, {
      headers: {
        'x-vol-tenant': this._config.tenant,
      },
    })
      .then((res) => res.json())
      .catch((err) => {
        return this.logAndThrow(err, {
          productId: id,
        });
      });
  }

  public getNewProductById(
    productId: string
  ): Promise<LambdaV2Responses['product'] & { productId: string }> {
    const include = [
      'variants.optionValues',
      'variants.images',
      'variants.prices',
      'seo',
      'categories',
      'images',
      'optionGroups.optionValues',
      'optionGroups.optionValues.images',
    ].join('&include=');
    return this._transport(
      `${this._baseUrl}/public/rest/products/${productId}?include=${include}&shape=legacy`,
      {
        headers: {
          'x-vol-tenant': this._config.tenant,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        return this.logAndThrow(err, {
          productId,
        });
      });
  }

  public getProductBySlug(slug: string): Promise<LambdaV2Responses['product']> {
    return this._transport(
      `${this._baseUrl}/store/products?filter=seo.friendlyName+eq+${slug}`,
      {
        headers: {
          'x-vol-tenant': this._config.tenant,
        },
      }
    )
      .then((res) => res.json())
      .then((products) => {
        if (!products.items.length) {
          throw new Error('Product not found');
        }
        const product = products.items[0];
        return product;
      })
      .catch((err) => {
        return this.logAndThrow(err, {
          productSlug: slug,
        });
      });
  }

  public getProductsByCategorySlug(
    filter: string
  ): Promise<LambdaV2Responses['products']> {
    return this._transport(`${this._baseUrl}/store/products?${filter}`, {
      headers: {
        'x-vol-tenant': this._config.tenant,
      },
    })
      .then((res) => res.json())
      .catch((err) => {
        return this.logAndThrow(err, {
          categorySlug: filter,
        });
      });
  }

  public async getRelatedProductsById(
    id: string
  ): Promise<Array<LambdaV2Responses['product']>> {
    const product = await this.getProductById(id);
    const relatedIds = product.relatedProductIds;
    return await Promise.all(
      relatedIds.map((productId: string) => {
        return this.getProductById(productId);
      })
    );
  }

  public async getRelatedProductsBySlug(
    slug: string
  ): Promise<Array<LambdaV2Responses['product']>> {
    const product = await this.getProductBySlug(slug);
    const relatedIds = product.relatedProductIds;
    return await Promise.all(
      relatedIds.map((productId: string) => {
        return this.getProductById(productId);
      })
    );
  }

  public async createCart(): Promise<CartApiResponse> {
    return (await this.getCartApi()).createCart().catch(this.logAndThrow);
  }

  public async getCart(cartId: string): Promise<CartApiResponse> {
    return (await this.getCartApi()).getCart(cartId);
  }

  public async updateCart(
    cartId: string,
    quantity: number,
    variantId: string
  ): Promise<CartApiResponse> {
    return (await this.getCartApi()).updateCart(cartId, quantity, variantId);
  }

  public async addContactAndShippingToCart(
    cartId: string,
    contactInfo: CartContactInfo,
    shippingMethod?: ShippingMethod
  ): Promise<LambdaV2Responses['cart']> {
    const cartResponse = (await this.getCart(
      cartId
    )) as LambdaV2Responses['cart'];
    cartResponse.contactInfo = cartResponse.contactInfo || {};
    cartResponse.contactInfo.email = contactInfo.email;
    cartResponse.shippingAddress = contactInfo.shippingAddress;
    cartResponse.billingAddress = contactInfo.billingAddress;
    if (shippingMethod) {
      cartResponse.shippingMethod = shippingMethod;
    } else if (cartResponse.availableShippingMethods.length > 0) {
      const defaultShippingMethod = formatShippingMethod(
        cartResponse.availableShippingMethods[0]
      );
      cartResponse.shippingMethod = defaultShippingMethod;
    }
    return this.saveCart(cartId, cartResponse);
  }

  public async setShopperId(
    cartId: string,
    shopperId: string
  ): Promise<CartApiResponse> {
    return (await this.getCartApi()).setShopperId(cartId, shopperId);
  }

  public async copyCartWithoutPersonalData(
    cartId: string
  ): Promise<CartApiResponse> {
    return (await this.getCartApi()).copyCartWithoutPersonalData(cartId);
  }

  public async removeDiscountFromCart(
    cartId: string,
    discountId: string
  ): Promise<CartApiResponse> {
    return (await this.getCartApi()).removeDiscountFromCart(cartId, discountId);
  }

  public async addToCart(
    cartId: string,
    productId: string,
    quantity: number,
    variantId: string
  ): Promise<CartApiResponse> {
    return (await this.getCartApi()).addToCart(
      cartId,
      productId,
      quantity,
      variantId
    );
  }

  public async addDiscountToCart(
    cartId: string,
    discountCode: string
  ): Promise<CartApiResponse> {
    return (await this.getCartApi()).addDiscountToCart(cartId, discountCode);
  }

  public createBag(
    cartId: string,
    returnUri: string
  ): Promise<LambdaV2Responses['bag']> {
    const params = {
      cartId,
      returnUri,
      tenantId: this._config.tenant,
    };

    return this._transport(`${this._baseUrl}/store/bag/`, {
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
        'x-vol-tenant': this._config.tenant,
      },
      method: 'POST',
    })
      .then((res) => this.handleErrors(res))
      .then((res: Response) => res.json())
      .catch((err) => {
        return this.logAndThrow(err, {
          cartId,
        });
      });
  }

  public setRestExpressCheckout(
    cartId: string
  ): Promise<RestExpressCheckoutResponse> {
    const params = { cartId };
    return this._transport(`${this._baseUrl}/public/rest/paypal/orders`, {
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
        'x-mat-tenant': this._config.tenant,
      },
      method: 'POST',
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return this.logAndThrow(err, {
          cartId,
        });
      });
  }

  public setExpressCheckout(
    returnUrl: string,
    cancelUrl: string,
    cartId: string
  ): Promise<LambdaV2Responses['expressCheckout']> {
    const params = {
      cancelUrl,
      cartId,
      returnUrl,
    };
    return this._transport(`${this._baseUrl}/paypal/setexpresscheckout`, {
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
        'x-mat-tenant': this._config.tenant,
      },
      method: 'POST',
    })
      .then((res) => this.handleErrors(res))
      .then((res: Response) => res.json())
      .catch((err) => {
        return this.logAndThrow(err, {
          cartId,
        });
      });
  }

  public searchKeys(): Promise<LambdaV2Responses['searchKeys']> {
    return this._transport(`${this._baseUrl}/store/searchKeys`, {
      headers: {
        'x-vol-tenant': this._config.tenant,
      },
    })
      .then((res) => res.json())
      .catch(this.logAndThrow);
  }

  public async getLatestCartForShopper(
    cartId: string,
    shopperId: string,
    shopperToken: string
  ): Promise<CartApiResponse> {
    return (await this.getCartApi()).getLatestCartForShopper(
      cartId,
      shopperId,
      shopperToken
    );
  }

  public getCategoryFlatList(): Promise<LambdaV2Responses['categoryTree']> {
    return this._transport(`${this._baseUrl}/store/categories`, {
      headers: {
        'x-vol-tenant': this._config.tenant,
      },
    })
      .then((res) => res.json())
      .catch(this.logAndThrow);
  }

  public async getShipToCountries(): Promise<LambdaV2Responses['countries']> {
    return this._transport(`${this._baseUrl}/store/shiptolocations`, {
      headers: {
        'x-vol-tenant': this._config.tenant,
      },
    })
      .then((res) => res.json())
      .catch(this.logAndThrow);
  }

  public async getMenus(): Promise<LambdaV2Responses['menus']> {
    return this._transport(`${this._baseUrl}/store/menus`, {
      headers: {
        'x-vol-tenant': this._config.tenant,
      },
    })
      .then((res) => res.json())
      .catch(this.logAndThrow);
  }

  public async getPaymentKeys(): Promise<LambdaV2Responses['paymentKeys']> {
    return this._transport(`${this._baseUrl}/store/paymentkeys`, {
      headers: {
        'x-vol-tenant': this._config.tenant,
      },
    })
      .then((res) => res.json())
      .catch(this.logAndThrow);
  }

  public async placeApplePayOrder(
    cart: LambdaV2Responses['cart'],
    stripeToken: StripeToken
  ): Promise<LambdaV2Responses['applePayOrder'] | void> {
    const order = getApplePayOrder(cart, stripeToken);
    try {
      const response = await this._transport(`${this._baseUrl}/store/orders`, {
        body: JSON.stringify(order),
        headers: {
          'Content-Type': 'application/json',
          'x-vol-tenant': this._config.tenant,
        },
        method: 'POST',
      });
      const success = this.handleErrors(response);
      return success.json();
    } catch (error) {
      this.logAndThrow(error as Error, {
        cart,
        stripeToken,
      });
    }
  }

  public async placeApplePayOrderWithNewCart(
    applePayOrderArgs: ApplePayOrderForRestCartArgs
  ): Promise<LambdaV2Responses['applePayOrder'] | void> {
    const order = getApplePayOrderForRestCart(applePayOrderArgs);
    try {
      const response = await this._transport(`${this._baseUrl}/store/orders`, {
        body: JSON.stringify(order),
        headers: {
          'Content-Type': 'application/json',
          'x-vol-tenant': this._config.tenant,
        },
        method: 'POST',
      });
      const success = this.handleErrors(response);
      return success.json();
    } catch (err) {
      this.logAndThrow(err as Error, {
        applePayOrderArgs,
      });
    }
  }

  public async getContentPageBySeoFriendlyName(
    pageUrlText: string
  ): Promise<LambdaV2Responses['contentPage']> {
    return this._transport(
      `${this._baseUrl}/contentPages/bySeoFriendlyName/${pageUrlText}`,
      {
        headers: {
          'x-vol-tenant': this._config.tenant,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.isActive === false) {
          throw new Error('page is marked as hidden');
        }
        return res;
      })
      .catch((err) => {
        return this.logAndThrow(err, {
          pageUrlText,
        });
      });
  }

  public async getCategoryBySlug(
    slug: string
  ): Promise<LambdaV2Responses['category']> {
    return this._transport(
      `${this._baseUrl}/store/categories/slug/${slug}?include=children&include=parents`,
      {
        headers: {
          'x-vol-tenant': this._config.tenant,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        return this.logAndThrow(err, {
          categorySlug: slug,
        });
      });
  }

  public async getCategoryById(
    id: string
  ): Promise<LambdaV2Responses['category']> {
    return this._transport(
      `${this._baseUrl}/store/categories/${id}?include=children&include=parents`,
      {
        headers: {
          'x-vol-tenant': this._config.tenant,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        return this.logAndThrow(err, {
          categoryId: id,
        });
      });
  }

  public async getAvailableShippingMethods(
    cartId: string,
    country: string,
    state: string
  ): Promise<AvailableShippingMethods> {
    const query = `country=${country}&state=${state}`;
    return this._transport(
      `${this._baseUrl}/public/rest/carts/${cartId}/shippingmethods?${query}`,
      {
        headers: {
          'x-vol-tenant': this._config.tenant,
        },
        method: 'GET',
      }
    )
      .then((res) => this.handleErrors(res))
      .then((res) => res.json())
      .catch((err) => {
        return this.logAndThrow(err, {
          cartId,
          country,
          state,
        });
      });
  }

  public async getTax(
    cartId: string,
    shippingAddress: PhysicalAddress,
    shippingPrice: number
  ): Promise<CartTax> {
    const body = JSON.stringify({
      shippingAddress,
      shippingPrice,
    });
    return this._transport(
      `${this._baseUrl}/public/rest/carts/${cartId}/taxes`,
      {
        headers: {
          'Content-Type': 'application/json',
          'x-vol-tenant': this._config.tenant,
        },
        method: 'POST',
        body,
      }
    )
      .then((res) => this.handleErrors(res))
      .then((res) => res.json())
      .catch((err) => {
        return this.logAndThrow(err, {
          cartId,
          shippingAddress,
          shippingPrice,
        });
      });
  }

  private async getCartApi(): Promise<CartApi> {
    if (this._useNewCart === null) {
      try {
        const storeInformation = await this.getStoreInformation();
        this._useNewCart = storeInformation.useNewCartService;
      } catch {
        this._useNewCart = false;
      }
    }
    return this._useNewCart
      ? new NewCartApi(this._transport, this._config)
      : new OldCartApi(this._transport, this._config);
  }

  private saveCart(
    cartId: string,
    cart: LambdaV2Responses['cart']
  ): Promise<LambdaV2Responses['cart']> {
    return this._transport(`${this._baseUrl}/carts/${cartId}`, {
      body: JSON.stringify(cart),
      headers: {
        'x-mat-tenant': this._config.tenant,
      },
      method: 'PUT',
    })
      .then((res) => this.handleErrors(res))
      .then((res: Response) => res.json())
      .catch((err) => {
        return this.logAndThrow(err, {
          cartId,
        });
      });
  }

  private handleErrors(res: Response): Response {
    if (!res.ok) {
      throw Error(res.statusText);
    }
    return res;
  }

  private logAndThrow = (
    error: Error,
    context: Record<string, any> = {}
  ): never => {
    if (this._config.logger) {
      let msg = `SDK request failure: ${error.message}`;

      if (context) {
        const allContext = JSON.stringify({
          ...context,
          tenant: this._config.tenant,
        });
        msg = `${msg} (${allContext})`;
      }

      this._config.logger(msg);
    }
    throw new Error(error.message);
  };
}
